<template>
  <div class="pa-4">
    <v-card
      class="mb-3"
      outlined
    >
      <v-toolbar flat>
        <v-toolbar-title>
          {{ $t('scheduledReports') }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          @click="close"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="py-0">
        <v-alert
          type="info"
          outlined
          color="blue"
          dense
        >
          {{ $t('scheduledReportsInformation') }}
        </v-alert>
      </v-card-text>
    </v-card>

    <v-card
      class="mb-3"
      outlined
    >
      <div class="mx-3">
        <v-card-title class="pl-4">
          {{ $t('recipients') }}
        </v-card-title>
        <v-progress-linear
          indeterminate
          v-if="loading"
        />
        <v-spacer />
        <v-list v-if="scheduledReports !== null && scheduledReports.length > 0">
          <template v-for="(scheduledReport, index) in scheduledReports">
            <v-list-item
              :key="scheduledReport.uuid"
              @click="selectScheduledReport(scheduledReport)"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ scheduledReport.messengerDestination }}
                </v-list-item-title>
                <v-list-item-subtitle
                  v-if="scheduledReport.interval==='daily'"
                  class="text--primary"
                >
                  {{ $t('daily') }}
                </v-list-item-subtitle>
               
                <v-list-item-subtitle
                  v-if="scheduledReport.interval==='weekly'"
                  class="text--primary"
                >
                  {{ $t('weekly') }}
                </v-list-item-subtitle>
               
                <v-list-item-subtitle
                  v-if="scheduledReport.interval==='monthly'"
                  class="text--primary"
                >
                  {{ $t('monthly') }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon>
                  <v-icon
                    @click.stop="removeScheduledReport(scheduledReport)"
                  >
                    mdi-trash-can-outline
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider
              v-if="index < scheduledReports.length -1"
              inset 
              :key="'d-' + index"
            />
          </template>
        </v-list>
        <v-list v-if="(scheduledReports === null || scheduledReports.length <= 0) && !loading">
          <v-list-item>
            <v-list-item-title>{{ $t('noScheduledReportsFoundForThisDashCollection') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </div>
    </v-card>
    <v-card
      outlined
      class="mb-3"
    >
      <div
        v-if="selectedReport"
        class="mx-6"
      >
        <v-card-title class="pl-0">
          {{ $t('updateRecipient') }}
        </v-card-title>
        <v-form>
          <v-text-field
            v-model="selectedReport.messengerDestination"
            outlined
            :label="$t('email')"
            validate-on-blur
            :rules="[rules.email]"
          />
          <!--Dont know if currency works-->
          <!--  <v-select
            outlined
            :label="$t('currency')'"
          /> -->
          <v-select
            v-model="selectedReport.interval"
            outlined
            :label="$t('frequency')"
            :items="intervals"
            item-text="label"
            item-value="key"
            validate-on-blur
            :rules="[rules.selectItemRequired]"
          />
          <v-select
            v-if="selectedReport.dayOfTheWeek"
            outlined
            :value="selectedReport.dayOfTheWeek"
            v-model="selectedReport.dayOfTheWeek"
            :label="$t('dayOfTheWeek')"
            :items="weekdays"
            item-text="label"
            item-value="key"
          />
          <v-select
            v-if="selectedReport.dayOfTheMonth"
            outlined
            :value="selectedReport.dayOfTheMonth"
            v-model="selectedReport.dayOfTheMonth"
            :label="$t('dayOfTheMonth')"
            :items="monthDays"
          />
          <TimeSelectorV2
            v-if="selectedReport.intervalTrigger"
            class="pb-6"
            :initial-time="selectedReport.intervalTrigger"
            @selectTime="selectTime"
          />
          <v-select
            outlined
            multiple
            v-model="selectedReport.channels"
            :label="$t('channel')"
            :items="activeChannels"
            item-text="label"
            item-value="uuid"
            validate-on-blur
            :rules="[rules.selectItemRequiredOnMultiple]"
          />
        </v-form>
      </div>
      <div
        v-else
        class="mx-6"
      >
        <v-card-title class="pl-0">
          {{ $t('newRecipient') }}
        </v-card-title>
        <v-form>
          <v-text-field
            v-model="newReport.messengerDestination"
            outlined
            :label="$t('email')"
            validate-on-blur
            :rules="[rules.email]"
          />
          <!--Dont know if currency works-->
          <!--  <v-select
            outlined
            :label="$t('currency')'"
          /> -->
          <v-select
            v-model="newReport.interval"
            outlined
            :label="$t('frequency')"
            :items="intervals"
            item-text="label"
            item-value="key"
            validate-on-blur
            :rules="[rules.selectItemRequired]"
          />
          <v-select
            v-if="newReport.dayOfTheWeek"
            outlined
            :value="newReport.dayOfTheWeek"
            v-model="newReport.dayOfTheWeek"
            :label="$t('dayOfTheWeek')"
            :items="weekdays"
            item-text="label"
            item-value="key"
          />
          <v-select
            v-if="newReport.dayOfTheMonth"
            outlined
            :value="newReport.dayOfTheMonth"
            v-model="newReport.dayOfTheMonth"
            :label="$t('dayOfTheMonth')"
            :items="monthDays"
          />
          <TimeSelectorV2
            class="pb-6"
            :initial-time="newReport.intervalTrigger" 
            @selectTime="selectTime"
          />
          <v-select
            outlined
            multiple
            v-model="newReport.channels"
            :label="$t('channel')"
            :items="activeChannels"
            item-text="label"
            item-value="uuid"
            validate-on-blur
            :rules="[rules.selectItemRequiredOnMultiple]"
          />
        </v-form>
      </div>
    </v-card>
    <v-card outlined>
      <v-toolbar flat>
        <v-spacer />
        <v-btn
          v-if="selectedReport"
          depressed
          color="black"
          outlined
          class="mr-5"
          @click="selectedReport = null"
        >
          {{ $t('newRecipient') }}
        </v-btn>
        <v-btn
          v-if="selectedReport"
          depressed
          color="#67AD5B"
          class="white--text"
          @click="updateScheduledReport"
          :disabled="!valuesAreValid"
        >
          {{ $t('updateRecipient') }}
        </v-btn>
        <v-btn
          v-if="!selectedReport"
          depressed
          color="#67AD5B"
          class="white--text"
          @click="createScheduledReport"
          :disabled="!valuesAreValid"
        >
          {{ $t('addRecipient') }}
        </v-btn>
      </v-toolbar>
    </v-card>
  </div>
</template>

<script>

import TimeSelectorV2 from "../common/TimeSelectorV2"

export default ({
    name: 'ScheduledReportsCard', 
    components: {
        TimeSelectorV2
    },
    props: {
        dashCollection: {
            type: Object, 
            default: () => {}
        }
    }, 
    data() {
        return {
            scheduledReports: [], 
            loading: false, 
            selectedReport: null, 
            newReport:null,
            initializeNewReport: {
                messengerDestination: null, 
                interval: null, 
                intervalTrigger: "08:00", 
                channels: [], 
                active: true, 
                messengerMethod: 'email',
                dayOfTheWeek: null,
                dayOfTheMonth: null,
            },
            intervals: [
                {key: 'daily', label: this.$t('daily')}, 
                {key: 'weekly', label: this.$t('weekly')},
                {key: 'monthly', label: this.$t('monthly')}
            ], 
            weekdays: [
              {key: '1', label: this.$t('monday')}, 
              {key: '2', label: this.$t('tuesday')}, 
              {key: '3', label: this.$t('wednesday')}, 
              {key: '4', label: this.$t('thursday')}, 
              {key: '5', label: this.$t('friday')}, 
              {key: '6', label: this.$t('saturday')}, 
              {key: '7', label: this.$t('sunday')}, 
            ]
        }
    }, 
    computed: {
        organization() {
          return this.$store.state.organization
        },
        activeChannels() {
          return this.$store.state.channels.filter(channel => channel.active)
        }, 
        monthDays() {
          const days = []
          for(let i = 0; i < 29; i++) {
            days.push((i + 1).toString())
          }
          return days
        }, 
        rules() {
          return this.$store.getters.rules
        }, 
        valuesAreValid() {
          if(!this.selectedReport) {
            if(this.rules.email(this.newReport.messengerDestination) !== true) {
              return false 
            }

            if(!this.newReport.interval) {
              return false 
            }

            if(this.newReport.channels && this.newReport.channels.length === 0) {
              return false 
            }

          } else {

            if(this.rules.email(this.selectedReport.messengerDestination) !== true) {
              return false 
            }

            if(!this.selectedReport.interval) {
              return false 
            }

            if(this.selectedReport.channels && this.selectedReport.channels.length === 0) {
              return false 
            }
            
          }
          return true 
        }
    },
    methods: {
        getScheduledReports() {
          this.loading = true
          this.$store.dispatch('getScheduledReports', this.dashCollection.uuid).then(res => {
              this.scheduledReports = res.filter(res => res.active === true)
          }).finally(() => {
              this.loading = false
          })
        },  
        removeScheduledReport(scheduledReport) {
          scheduledReport.active = false
          scheduledReport.organization = this.organization.uuid
          scheduledReport.dashCollection = this.dashCollection.uuid
        
          this.$store.dispatch('updateScheduledReport', scheduledReport).then(res => {
            if(res) {
              this.$store.commit('updateActionSuccess', {
                message: this.$t('success'), 
                subMessage: this.$t('scheduledReportRemoved')
              })
              this.getScheduledReports()
            } else {
              this.$store.commit('updateActionError', {
                message: this.$t('error'), 
                subMessage: this.$t('failedToRemoveScheduledReport')
              })
            } 
          }).catch(() => {
            this.$store.commit('updateActionError', {
              message: this.$t('error'), 
              subMessage: this.$t('failedToRemoveScheduledReport')
            })
          })
        },
        updateScheduledReport() {
          this.selectedReport.organization = this.organization.uuid
          this.selectedReport.dashCollection = this.dashCollection.uuid
          
          this.$store.dispatch('updateScheduledReport', this.selectedReport).then(res => {
            if(res) {
              this.$store.commit('updateActionSuccess', {
                message: this.$t('success'), 
                subMessage: this.$t('scheduledReportUpdated')
              })
              this.getScheduledReports()
            } else {
              this.$store.commit('updateActionError', {
                message: this.$t('error'), 
                subMessage: this.$t('failedToUpdateScheduledReport')
              })
            } 
          }).catch(() => {
            this.$store.commit('updateActionError', {
              message: this.$t('error'), 
              subMessage: this.$t('failedToUpdateScheduledReport')
            })
          })
        },  
        createScheduledReport() {
          this.newReport.organization = this.organization.uuid
          this.newReport.dashCollection = this.dashCollection.uuid
          
          this.$store.dispatch('createScheduledReport', this.newReport).then(res => {
            if(res) {
              this.$store.commit('updateActionSuccess', {
                message: this.$t('success'), 
                subMessage: this.$t('scheduledReportCreated')
              })
              this.getScheduledReports()
            } else {
              this.$store.commit('updateActionError', {
                message: this.$t('error'), 
                subMessage: this.$t('failedToCreateScheduledReport')
              })
            } 
          }).catch(() => {
            this.$store.commit('updateActionError', {
              message: this.$t('error'), 
              subMessage: this.$t('failedToCreateScheduledReport')
            })
          })
        },
        selectTime(value) {
            if(this.selectedReport) {
                this.selectedReport.intervalTrigger = value
            } else {
                this.newReport.intervalTrigger = value
            }
        },
        selectScheduledReport(scheduledReport) {
            this.selectedReport = scheduledReport
            this.newReport = Object.assign({},this.initializeNewReport)  
        }, 
        close() {
          this.$emit('close')
        }
    },
    watch: {
      'newReport.interval'(value) {
        if(value === 'daily') {
          this.newReport.dayOfTheMonth = null
          this.newReport.dayOfTheWeek = null 
        }

        if(value === 'weekly') {
          this.newReport.dayOfTheMonth = null
          this.newReport.dayOfTheWeek = '1'
        }
        
        if(value === 'monthly') {
          this.newReport.dayOfTheWeek = null
          this.newReport.dayOfTheMonth = '1'
        }
      }, 
      'selectedReport.interval'(value) {
        if(value === 'daily') {
          this.selectedReport.dayOfTheMonth = null
          this.selectedReport.dayOfTheWeek = null
        }

        if(value === 'weekly') {
          this.selectedReport.dayOfTheMonth = null
          this.selectedReport.dayOfTheWeek = '1'
        }
        
        if(value === 'monthly') {
          this.selectedReport.dayOfTheWeek = null
          this.selectedReport.dayOfTheMonth = '1'
        }
      }, 
    },
    mounted() {  
        this.newReport = Object.assign({},this.initializeNewReport)
        this.getScheduledReports()
    }
})
</script>
